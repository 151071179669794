
<script>
  export default {
    props:{
      props: Object
    },
    data() {
      return {
        posts: [
          {
            title: "¿Por qué los gatos ronronean?",
            imageURL: "https://via.placeholder.com/300x200"
          },
          {
            title: "Debemos cepillar los dientes de nuestros gatos",
            imageURL: "https://via.placeholder.com/300x200"
          },
          {
            title: "Las mascotas son un apoyo incondicional",
            imageURL: "https://via.placeholder.com/300x200"
          },
        ]
      }
    },
    methods: {
      async getLastestPosts() {
        try {

        } catch(e) {
          console.error(e);
        }
      }
    },
    created() {
      this.getLastestPosts();
    }
  };
</script>

<template>
  <div class="blog-widget py-5 text-center">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="font-weight-bold">{{props.title}}</h2>
        </div>
        <div class="col-12 mb-4">
          <p class="lead subtitle">{{props.Description}}</p>
        </div>
        <div v-for="(item, index) in props.items"
          v-bind:key="index"
          class="col-lg-4 col-md-6 mb-4 pb-2">
          <div class="card blog rounded border-0 shadow overflow-hidden">
            <div class="position-relative">
              <img v-bind:src="item.imageURL" alt="Blog cover" class="card-img-top">
              <div class="overlay rounded-top bg-dark"></div>
            </div>
            <div class="card-body content">
              <h5>
                <a href="javascript:void(0)" class="card-title title text-dark">{{item.content}}</a>
              </h5>
              <div class="post-meta d-flex justify-content-between mt-3">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item mr-2 mb-0">
                    <a href="javascript:void(0)" class="text-muted like">
                      <i class="mdi mdi-heart-outline mr-1"></i>33
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="javascript:void(0)" class="text-muted comments">
                      <i class="mdi mdi-comment-outline mr-1"></i>08
                    </a>
                  </li>
                </ul>
                <a href="/page-blog-detail" class="text-muted readmore">
                  Read More <i class="mdi mdi-chevron-right"></i>
                </a>
              </div>
            </div>
            <div class="author">
              <small class="text-light user d-block">
                <i class="mdi mdi-account"></i> {{item.imgHover}}
              </small>
              <small class="text-light date">
                <i class="mdi mdi-calendar-check"></i> {{item.date}}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

